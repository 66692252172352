.auth-layout-bg-col {
  position: relative;

  &:before {
    content: "";
    /* background: radial-gradient(#d2f1df, #d3d7fa, #bad8f4); */
    background-color: rgba(99, 137, 223, 0.05);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.auth-layout-form-container {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.auth-layout-custom-row {
  overflow: hidden !important;
}
